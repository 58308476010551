import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge } from '@sumup/circuit-ui';

export const OutOfStockBadge = styled(Badge, {
  shouldForwardProp: (propName) => propName !== 'isOutOfStock',
})<{ isOutOfStock?: boolean }>(
  ({ isOutOfStock = false }) =>
    css`
      ${isOutOfStock ? '' : 'visibility: hidden;'}
      background-color: var(--cui-bg-warning-strong);
      color: var(--cui-fg-on-strong);
    `,
);
