import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge, type BadgeProps } from '@sumup/circuit-ui';
import type { Theme } from '@sumup/design-tokens';

import { ProductCardSizes } from '../ProductCard/ProductCard.types';

const defaultStyles = (variant: string) =>
  !variant &&
  css`
    background-color: var(--cui-bg-accent);
    color: var(--cui-fg-accent);
  `;

export const DiscountBadge = styled(Badge)(
  ({
    theme,
    variant,
    isFree,
    size,
  }: BadgeProps & { theme: Theme } & {
    isFree?: boolean;
    size?: ProductCardSizes;
  }) => css`
    color: var(--cui-fg-on-strong);
    margin-bottom: var(--cui-spacings-byte);
    ${defaultStyles(variant)};

    user-select: none;
    ${theme.mq.mega} {
      user-select: auto;
    }

    ${!size &&
    isFree &&
    `
      margin-bottom: ${theme.spacings.giga};
    `}

    ${size === ProductCardSizes.small &&
    `
      margin-bottom: calc(${theme.spacings.giga} - ${theme.spacings.byte});
    `}
    ${size === ProductCardSizes.medium &&
    `
      margin-bottom: calc(${theme.spacings.giga} * 2);
    `}
    ${size === ProductCardSizes.large &&
    `
      margin-bottom: calc((${theme.spacings.tera} * 2) - ${theme.spacings.bit});
    `}
    ${size === ProductCardSizes.extraLarge &&
    `
      margin-bottom: calc(${theme.spacings.tera} * 2 + ${theme.spacings.bit});
    `}
  `,
);
