import { css } from '@emotion/react';
import type { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Button, Headline } from '@sumup/circuit-ui';
import NextLink from 'next/link';

import { QuantitySelector } from 'productSelection/pages/ProductDetails/QuantitySelector';
import { getMarginBottom } from 'productSelection/services/priceStyle';
import { InternalLink } from 'shared/components/Link';

import { DiscountBadge } from '../DiscountBadge';
import { OutOfStockBadge } from '../OutOfStockBadge';

import type { ProductCardSizes } from './ProductCard.types';

export const RelativeDiv = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: var(--cui-spacings-giga);
`;

export const ImageRatio = styled.div`
  aspect-ratio: 1/1;
  position: relative;
  width: 100%;
`;

export const StyledBadge = styled(DiscountBadge)(
  ({ theme }) => css`
    position: absolute;
    top: var(--cui-spacings-byte);
    left: var(--cui-spacings-byte);
    margin: 0;
    font-size: 11px;
    padding: 0 7px;

    ${theme.mq.giga} {
      font-size: var(--cui-typography-body-two-font-size);
      line-height: var(--cui-typography-body-two-line-height);
      padding: 2px 8px;
    }
  `,
);

export const StyledOutOfStockBadge = styled(OutOfStockBadge)(
  ({ theme }) => css`
    position: absolute;
    top: var(--cui-spacings-byte);
    left: var(--cui-spacings-byte);
    margin: 0;
    font-size: 11px;
    padding: 0 7px;

    ${theme.mq.giga} {
      font-size: var(--cui-typography-body-two-font-size);
      line-height: var(--cui-typography-body-two-line-height);
      padding: 2px 8px;
    }
  `,
);

export const ProductContent = styled.div(
  ({
    theme,
    isHighlighted = false,
  }: {
    theme?: Theme;
    isHighlighted?: boolean;
  }) => css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: var(--cui-spacings-byte) var(--cui-spacings-byte)
      var(--cui-spacings-mega);
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    border-top: none;
    border-bottom-left-radius: var(--cui-border-radius-mega);
    border-bottom-right-radius: var(--cui-border-radius-mega);

    ${isHighlighted &&
    css`
      border-color: var(--cui-border-accent);
      border-width: var(--cui-border-width-mega);
    `}

    ${theme.mq.mega} {
      padding-left: var(--cui-spacings-mega);
      padding-right: var(--cui-spacings-mega);
      padding-bottom: var(--cui-spacings-giga);
    }
  `,
);

export const NameLink = styled(NextLink)`
  text-decoration: none;
  color: var(--cui-fg-normal);
  text-decoration: none;
`;

export const PriceWrapper = styled.div`
  margin-top: auto;
`;

export const StrikeThroughInstallmentsPrice = styled(Body)(
  ({ theme }) => css`
    color: var(--cui-fg-subtle);
    text-decoration: line-through;
    user-select: none;

    ${theme.mq.mega} {
      user-select: auto;
    }
  `,
);

export const PriceContainer = styled.div<{
  isOnBottomBar?: boolean;
  isPrimaryColor?: boolean;
  isVerticallyCenter?: boolean;
}>(
  ({
    theme,
    isOnBottomBar = false,
    isPrimaryColor = false,
    isVerticallyCenter = false,
  }) => css`
    display: flex;
    flex-direction: row;
    width: ${isOnBottomBar ? 'auto' : '100%'};
    align-items: flex-end;
    justify-content: center;
    user-select: none;

    ${isPrimaryColor ? 'color: var(--cui-fg-accent)' : ''}
    ${isVerticallyCenter ? 'margin: auto 0;' : ''}

    ${theme.mq.mega} {
      user-select: auto;
    }
  `,
);

export const InstallmentsPrice = styled(Headline)`
  color: var(--cui-fg-accent);
`;

export const StyledInstallmentPrice = styled(InstallmentsPrice)(
  ({ theme }) =>
    css`
      color: var(--cui-fg-normal);
      font-size: var(--cui-typography-headline-two-font-size);
      line-height: var(--cui-typography-headline-two-line-height);

      ${theme.mq.giga} {
        font-size: var(--cui-typography-headline-one-font-size);
        line-height: var(--cui-typography-headline-one-line-height);
      }
    `,
);

export const InstallmentsPriceInfo = styled(Body)(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 1.2;

    ${theme.mq.kilo} {
      font-size: var(--cui-typography-body-two-font-size);
      line-height: var(--cui-typography-body-two-line-height);
    }

    ${theme.mq.mega} {
      font-size: var(--cui-typography-body-one-font-size);
      line-height: var(--cui-typography-body-one-line-height);
    }
  `,
);

export const StrikeThroughPrice = styled(Body)<{
  productCardSize?: ProductCardSizes;
}>(
  ({ theme, productCardSize }) => css`
    color: var(--cui-fg-subtle);
    margin-left: var(--cui-spacings-byte);
    text-decoration: line-through;

    ${productCardSize !== undefined
      ? getMarginBottom(theme, productCardSize)
      : ''}
  `,
);

export const CTAsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ProductHandles = styled.div(
  ({ theme }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--cui-spacings-mega);
    width: 100%;

    ${theme.mq.giga} {
      flex-direction: row;
      justify-content: left;
      margin-bottom: 0;
    }
  `,
);

export const StyledQuantitySelector = styled(QuantitySelector)(
  ({ theme }) => css`
    flex-shrink: 0;
    margin-bottom: var(--cui-spacings-kilo);
    width: 100%;

    ${theme.mq.giga} {
      margin-bottom: 0;
      margin-right: var(--cui-spacings-kilo);
      width: auto;
    }
  `,
);

export const SelectButton = styled(Button)(
  ({ theme }) => css`
    flex-grow: 1;
    max-width: 100%;
    user-select: none;
    width: auto;

    ${theme.mq.mega} {
      user-select: auto;
    }

    ${theme.mq.untilGiga} {
      width: 100%;
    }
  `,
);

export const MoreInfoCTA = styled(InternalLink)(
  ({ theme }) => css`
    display: block;
    text-align: center;
    width: 100%;

    ${theme.mq.giga} {
      margin-top: var(--cui-spacings-mega);
    }
  `,
);
