import styled from '@emotion/styled';

export const StyledWrapper = styled.div`
  max-width: 488px;
  padding: 8px;
`;

export const StyledDetails = styled.details`
  margin-top: var(--cui-spacings-byte);

  & > summary::after {
    content: url('https://circuit.sumup.com/icons/v2/chevron_down_16.svg');
    position: relative;
    top: 2px;
    left: var(--cui-spacings-bit);
  }

  &[open] > summary::after {
    content: url('https://circuit.sumup.com/icons/v2/chevron_up_16.svg');
  }
`;

export const StyledSummary = styled.summary`
  text-decoration: underline;
  display: inline;
  cursor: pointer;
`;

export const DescriptionWrapper = styled.div`
  margin-top: var(--cui-spacings-giga);

  h3 {
    margin-bottom: var(--cui-spacings-mega);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
