import { useEffect, useMemo } from 'react';

import { useCoupon } from 'checkout/hooks/useCoupon';
import { getPromotionPRCs } from 'productSelection/services/promotions';
import type { SkuPriceData } from 'shared/infra/storefront/markets/prices';
import { useTypedSelector } from 'shared/store';

export const usePromotionPRCs = (
  prices: Record<string, SkuPriceData>,
): void => {
  const { addCoupon } = useCoupon();
  const prcs = useMemo(() => getPromotionPRCs(), []);
  const { orderDetails, products: lineItems } = useTypedSelector(
    (state) => state.order,
  );
  const {
    id: orderId,
    status: orderStatus,
    couponCode: orderCouponCode,
    metadata: orderMetadata,
  } = orderDetails;

  // sync ordered line item promotions with prc params
  useEffect(() => {
    // only run this useEffect on editable orders
    // this effect might trigger when we load the order but
    // before we have removed the order from the store
    if (!['pending', 'draft'].includes(orderStatus)) {
      return;
    }

    // quote orders are non-editable, so avoid updating coupon
    if (orderMetadata?.sales_channel === 'quotes') {
      return;
    }

    const lineItemCodes = lineItems.map((lineItem) => lineItem.code);
    // attach the coupon code
    if (orderId && !orderCouponCode) {
      lineItemCodes.forEach((code) => {
        const priceBySku = Object.values(prices).find(
          (priceInfo) => priceInfo.code === code,
        );
        const applicableCoupon = priceBySku?.appliedCouponCode;

        if (applicableCoupon) {
          void addCoupon(applicableCoupon, orderId);
        }
      });
    }

    // clear the coupon code
    if (orderCouponCode) {
      const availablePromotions = Object.values(prices).reduce<string[]>(
        (acc, priceInfo) =>
          priceInfo.appliedCouponCode
            ? [...acc, priceInfo.appliedCouponCode]
            : acc,
        [],
      );

      if (
        !availablePromotions.includes(orderCouponCode) ||
        !prcs.includes(orderCouponCode)
      ) {
        void addCoupon('', orderId);
      }
    }
  }, [
    addCoupon,
    prcs,
    orderCouponCode,
    orderId,
    orderStatus,
    lineItems,
    orderMetadata,
    prices,
  ]);
};
