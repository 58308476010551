import queryString from 'query-string';

import isServer from 'shared/utils/is-server';
import { getLast } from 'utils/querystring';

const PRC_DELIMITER = '-s-';

export const getPromotionPRCs = (searchString?: string): string[] => {
  const getSearchParamsFromWindow = !isServer && !searchString;
  const searchParams = getSearchParamsFromWindow
    ? window.location.search
    : searchString || '';

  const { prc = [] } = queryString.parse(searchParams);

  const prcs = prc.length
    ? getLast(prc)
        .split(PRC_DELIMITER)
        .map((p) => p.toUpperCase())
    : [];

  return prcs;
};
