import { Badge, Body } from '@sumup/circuit-ui';
import NextImage from 'next/image';
import { useEffect, type FC } from 'react';

import type { ProductSelectorDetails } from 'productSelection/types/products';
import {
  dispatchBusinessAccountSelectedEvent,
  dispatchBusinessAccountUnselectedEvent,
} from 'shared/services/tracker/events';

import { RichText } from '../RichText';
import { defaultRenderOptions as richTextOptions } from '../RichText/configs/richTextOptions';

import {
  BadgeWrapper,
  BenefitGrid,
  BenefitIcon,
  Card,
  CardHeadline,
  CardWrapper,
  CurrentFee,
  FeeWrapper,
  NewFee,
  OldFee,
  StyledListItem,
  StyledRadioInput,
} from './BusinessAccountSelector.styles';

export interface Props {
  selectedCard: string;
  setSelectedCard: Function;
  accessedFromPDP?: boolean;
  productSelector: ProductSelectorDetails;
  businessAccountSelector: ProductSelectorDetails;
  transactionFee: string;
  businessAccountFee: string;
}

export const CARD_OPTIONS = {
  withBusinessAccount: 'with-business-account',
  withoutBusinessAccount: 'without-business-account',
};

export const PDP_LABEL = 'product_details';
export const BA_LEARN_MORE_LABEL = 'ba_learn_more';

export const BusinessAccountSelector: FC<Props> = ({
  selectedCard,
  setSelectedCard,
  accessedFromPDP,
  productSelector,
  businessAccountSelector,
  transactionFee,
  businessAccountFee,
}) => {
  const label = accessedFromPDP ? PDP_LABEL : BA_LEARN_MORE_LABEL;

  const handleSelectOfferFee = (): void => {
    setSelectedCard(CARD_OPTIONS.withBusinessAccount);
    void dispatchBusinessAccountSelectedEvent({ label });
  };

  const handleSelectRegularFee = (): void => {
    setSelectedCard(CARD_OPTIONS.withoutBusinessAccount);
    void dispatchBusinessAccountUnselectedEvent({ label });
  };

  useEffect(() => {
    if (selectedCard === CARD_OPTIONS.withBusinessAccount) {
      void dispatchBusinessAccountSelectedEvent({ label });
    } else {
      void dispatchBusinessAccountUnselectedEvent({ label });
    }
  }, [label, selectedCard]);

  const withBusinessAccountOption = {
    ...businessAccountSelector,
    transactionFee: businessAccountFee,
    oldTransactionFee: transactionFee,
    id: CARD_OPTIONS.withBusinessAccount,
    onChange: handleSelectOfferFee,
  };

  const withoutBusinessAccountOption = {
    ...productSelector,
    transactionFee,
    oldTransactionFee: undefined,
    id: CARD_OPTIONS.withoutBusinessAccount,
    onChange: handleSelectRegularFee,
  };

  const items = [withBusinessAccountOption, withoutBusinessAccountOption];

  return (
    <CardWrapper>
      {items.map((item) => (
        <StyledListItem key={item.id}>
          <StyledRadioInput
            id={item.id}
            type="radio"
            name="radio-button"
            checked={selectedCard === item.id}
            onChange={item.onChange}
          />
          <Card htmlFor={item.id}>
            <CardHeadline size="four" as="h3">
              {item.title}
            </CardHeadline>
            <BadgeWrapper>
              {item.badgeText && (
                <Badge variant="success">{item.badgeText}</Badge>
              )}
            </BadgeWrapper>
            <Body size="two" variant="subtle">
              <RichText
                richText={item.description}
                renderMark={richTextOptions.renderMark}
                renderNode={richTextOptions.renderNode}
              />
            </Body>
            <FeeWrapper>
              {item?.oldTransactionFee ? (
                <>
                  <OldFee>{item.oldTransactionFee}</OldFee>
                  <NewFee>{item.transactionFee}</NewFee>
                </>
              ) : (
                <CurrentFee>{item.transactionFee}</CurrentFee>
              )}
            </FeeWrapper>

            {item.productBenefits.map((benefit, idx) => (
              <BenefitGrid key={`${benefit.name}-${idx}`}>
                <BenefitIcon>
                  <NextImage
                    src={benefit.icon.imageSrc}
                    alt={benefit.icon.imageAltText}
                    fill
                    sizes="100vw"
                  />
                </BenefitIcon>

                <Body size="two" variant="subtle">
                  <RichText
                    richText={benefit.title}
                    renderMark={richTextOptions.renderMark}
                    renderNode={richTextOptions.renderNode}
                  />
                </Body>
              </BenefitGrid>
            ))}
          </Card>
        </StyledListItem>
      ))}
    </CardWrapper>
  );
};
