import { Body, Headline, useModal } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLineItems } from 'checkout/hooks/useLineItems';
import { formatPurchaseEventProducts } from 'checkout/services/PaymentService';
import { getFormattedInstallmentAmount } from 'productSelection/services/product';
import type { Product } from 'productSelection/types/products';
import * as TestIds from 'shared/constants/TestIds';
import type { SkuCodePurchaseLimit } from 'shared/infra/storefront/markets/prices';
import { dispatchGoToCartEvent } from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';
import { proposeBusinessAccount } from 'shared/store/order/actions';
import type { OrderDetails } from 'shared/store/order/types';
import type { CartContent } from 'shared/types/cart';
import { getChannelLink } from 'shared/utils/channel-link';
import {
  addBusinessAccount,
  ensureBAModalIsCompleted,
  isBAModalCompleted,
  makeBusinessAccountProduct,
  makeBusinessAccountProductForTracking,
  removeBusinessAccountProductsFromCart,
  type BusinessAccountProduct,
} from 'src/cart/services/BusinessAccountService';
import { getOrderTotalAmountFormatted } from 'utils/tax';

import { BusinessAccountModal } from '../BusinessAccountModal';
import { OrderOverviewContainer } from '../OrderOverview';

import {
  CartContainer,
  CartWrapper,
  NotificationIconButton,
  PriceContainer,
  StyledButton,
  StyledModalWrapper,
  StyledShop,
} from './Cart.styles';

export interface CartProps extends CartContent, Partial<OrderDetails> {
  products?: Product[];
  productsContentful: Product[];
  defaultTaxRate: number;
  purchaseLimitsBySkuCodes: SkuCodePurchaseLimit[];
  isBusinessAccountBundleEnabled?: boolean;
  isLogoClickable: boolean;
}

/**
 * The cart component describes the shared collapsed cart
 * used to track on cart products during the whole purchase journey.
 */
export const Cart: FC<CartProps> = (props) => {
  const {
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    largestInstallmentInCart,
    primaryButtonText,
    goToCartLabel,
    defaultTaxRate = 0,
    purchaseLimitsBySkuCodes,
    productsContentful,
    isBusinessAccountBundleEnabled,
  } = props;

  const router = useRouter();
  const { locale, push, query } = router;
  const dispatch = useDispatch();

  const { orderDetails, products: productsInCart } = useTypedSelector(
    (state) => state.order,
  );

  const catalog = useTypedSelector((state) => state.catalog);
  const shippingAddress = useTypedSelector(
    (state) => state.addresses.shippingAddress,
  );

  const productsInCartWithoutBA = removeBusinessAccountProductsFromCart(
    productsInCart,
    productsContentful,
  );

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setModal, removeModal } = useModal();
  const hasProducts = productsInCartWithoutBA?.length > 0;
  const isCartDisabled = isModalOpen || !orderDetails.id;
  const quantityInCart = useMemo(
    () =>
      productsInCartWithoutBA?.reduce(
        (acc, product) => acc + product.quantity,
        0,
      ),
    [productsInCartWithoutBA],
  );

  const hasInstallments = largestInstallmentInCart > 1;
  const formattedInstallmentAmount = getFormattedInstallmentAmount(
    totalAmountFloat,
    largestInstallmentInCart,
    locale,
  );

  const handleGoToCartClick = useCallback(
    (
      isBusinessAccountSelected?: boolean,
      baProduct?: BusinessAccountProduct,
    ) => {
      setLoading(true);
      removeModal();

      const productsForTracking = formatPurchaseEventProducts(
        productsInCart,
        catalog,
      );

      if (isBusinessAccountSelected && !!baProduct) {
        productsForTracking.push(
          makeBusinessAccountProductForTracking(baProduct, catalog),
        );
      }

      void dispatchGoToCartEvent({
        products: productsForTracking,
        currency: orderDetails.currencyCode,
        total: orderDetails.totalAmountFloat,
      });

      void push({
        pathname: getChannelLink(`/cart/${orderDetails.id}`),
        query,
      });
    },
    [
      removeModal,
      productsInCart,
      catalog,
      orderDetails.currencyCode,
      orderDetails.totalAmountFloat,
      orderDetails.id,
      push,
      query,
    ],
  );

  const businessAccountModalChildren = useCallback(
    (businessAccountProduct: BusinessAccountProduct) => (
      <BusinessAccountModal
        product={businessAccountProduct.product}
        // isBusinessAccountSelected needs to be passed through because setModal is preventing
        // the component state to be updated (memoized function).
        onProceedClick={async (isBusinessAccountSelected: boolean) => {
          if (isBusinessAccountSelected) {
            await addBusinessAccount(
              orderDetails.id,
              businessAccountProduct,
              catalog,
            );
          }

          ensureBAModalIsCompleted();

          return handleGoToCartClick(
            isBusinessAccountSelected,
            businessAccountProduct,
          );
        }}
      />
    ),
    [orderDetails.id, catalog, handleGoToCartClick],
  );

  const openBusinessAccountModal = useCallback(
    (businessAccountProduct: BusinessAccountProduct): void => {
      const modal = {
        children: businessAccountModalChildren(businessAccountProduct),
        preventClose: false,
        closeButtonLabel: 'Close modal',
      };

      setModal(modal);
    },
    [setModal, businessAccountModalChildren],
  );

  const handleGoToCart = useCallback(() => {
    if (isBAModalCompleted()) {
      handleGoToCartClick();
      return;
    }

    const businessAccountProduct = makeBusinessAccountProduct(
      productsContentful,
      productsInCart,
    );

    if (!isBusinessAccountBundleEnabled || !businessAccountProduct) {
      handleGoToCartClick();
      return;
    }

    dispatch(proposeBusinessAccount(true));
    openBusinessAccountModal(businessAccountProduct);
  }, [
    productsContentful,
    productsInCart,
    isBusinessAccountBundleEnabled,
    dispatch,
    openBusinessAccountModal,
    handleGoToCartClick,
  ]);

  const { changeLineItemQuantityDebounced } = useLineItems();

  const onLineItemQuantityChange = useCallback(
    (product: Product) =>
      async (quantity: number): Promise<void> => {
        await changeLineItemQuantityDebounced(product, quantity);
      },
    [changeLineItemQuantityDebounced],
  );

  const Modal = useMemo(
    () => (
      <StyledModalWrapper data-testid="order-overview-modal">
        <Headline
          size="four"
          as="h2"
          css={{ marginBottom: 'var(--cui-spacings-mega)' }}
        >
          {props.overviewTitleText}
        </Headline>
        <OrderOverviewContainer
          {...props}
          purchaseLimitsBySkuCodes={purchaseLimitsBySkuCodes}
          onLineItemQuantityChange={onLineItemQuantityChange}
          overviewTitleText={null}
          usedInModal
          usedForCart
          orderId={orderDetails.id}
          noMargin={false}
        />
      </StyledModalWrapper>
    ),
    [
      props,
      purchaseLimitsBySkuCodes,
      onLineItemQuantityChange,
      orderDetails.id,
    ],
  );

  const openCartModal = useCallback(() => {
    setIsModalOpen(true);
    setModal({
      onClose: () => setIsModalOpen(false),
      children: Modal,
      closeButtonLabel: primaryButtonText,
    });
  }, [Modal, primaryButtonText, setModal]);

  const totalAmountFormatted = getOrderTotalAmountFormatted({
    shippingAddress,
    defaultTaxRate,
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    locale,
    doFallbackToDefaultTaxRate: true,
  });

  return (
    <CartWrapper data-testid="shopping-cart">
      <CartContainer>
        <PriceContainer>
          <NotificationIconButton
            data-testid={TestIds.ProductSelection.OpenCartBtn}
            label="shopping-cart"
            onClick={openCartModal}
            quantity={quantityInCart}
            disabled={isCartDisabled}
          >
            <StyledShop />
          </NotificationIconButton>
          {hasInstallments ? (
            <>
              <Body
                size="two"
                variant="subtle"
                css={{ marginRight: 'var(--cui-spacings-bit)' }}
              >
                {largestInstallmentInCart}x
              </Body>
              <Body variant="highlight">{formattedInstallmentAmount}</Body>
            </>
          ) : (
            <Body variant="highlight">{totalAmountFormatted}</Body>
          )}
        </PriceContainer>
        <StyledButton
          variant="primary"
          onClick={handleGoToCart}
          disabled={!hasProducts}
          data-testid={TestIds.ProductSelection.GoToCartBtn}
          loadingLabel="loading"
          isLoading={loading}
        >
          {goToCartLabel}
        </StyledButton>
      </CartContainer>
    </CartWrapper>
  );
};
