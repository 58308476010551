import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { ProductCardSizes } from 'productSelection/components/ProductCard/ProductCard.types';
import {
  getMarginBottom,
  type Spacing,
} from 'productSelection/services/priceStyle';

export const StrikeThroughPrice = styled.span<{
  size?: ProductCardSizes | Spacing;
}>(
  ({ theme, size }) => css`
    text-decoration: line-through;
    font-size: var(--cui-typography-body-one-font-size);
    line-height: var(--cui-typography-body-one-line-height);
    color: var(--cui-fg-subtle);
    margin-left: var(--cui-spacings-byte);
    ${size !== undefined ? getMarginBottom(theme, size) : ''}
  `,
);
