import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPromotionPRCs } from 'productSelection/services/promotions';
import {
  getPromotionsByCouponCode,
  type Promotion,
} from 'shared/infra/commerceLayer/promotions';
import { useTypedSelector, type AppState } from 'shared/store';
import {
  loadPromotionsFailure,
  loadPromotionsSuccess,
} from 'shared/store/promotions/actions';
import { DEFAULT_LOCALE } from 'tools/locales';

interface PromotionsConfig {
  forSkuCode?: string;
  locale?: string;
  marketCode: string;
}

export const usePromotions = (
  config?: PromotionsConfig,
): AppState['promotions'] => {
  const { forSkuCode, locale, marketCode } = config || {
    locale: DEFAULT_LOCALE,
  };

  const promotions = useTypedSelector((state) => state.promotions);
  const skuPromotion = promotions.details[forSkuCode];

  const dispatch = useDispatch();

  // load promotions
  useEffect(() => {
    const parsedCoupons = getPromotionPRCs();
    const shouldLoadPromotions = parsedCoupons.length && !skuPromotion;

    if (!shouldLoadPromotions) {
      return;
    }

    const loadPromotions = async (
      coupons: string[],
    ): Promise<Record<string, Promotion> | undefined> => {
      try {
        const promotionsByCode = await getPromotionsByCouponCode(coupons);

        dispatch(loadPromotionsSuccess(promotionsByCode));

        return promotionsByCode;
      } catch (error) {
        dispatch(loadPromotionsFailure('Error loading promotions.'));
      }

      return undefined;
    };

    void loadPromotions(parsedCoupons);

    // fetching promotions depends on the locale so anytime it changes we should execute again
  }, [dispatch, locale, marketCode, skuPromotion]);

  return promotions;
};
